.entries {
    display: flex;
    gap: 5px;
    margin-left: 10px;
    flex-wrap: wrap;
    max-width: 700px;
    color: teal;
}

.entries div {
    margin: 5px 0;
}

.entries button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 60px;
    width: 170px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
}

.cons_info {
    padding: 5px;
    border: 2px solid teal;
    border-radius: 10px;
    max-width: 700px;
    margin-top: 5px;
}

.cons_info div {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
}

.cons_info div p {
    min-width: 90px;
}

.cons_info hr {
    background: linear-gradient(45deg, teal, #47a8a8, whitesmoke);
    margin: 2px 0;
    height: 2px;
    border: none;
    width: 90%;
}

.meetings {
    color: teal;
    margin: 5px;
    max-width: 700px;
}

.meetings_buttons {
    margin: 10px 0 5px 0;
    gap: 5px;
    display: flex;
}

.meetings_buttons button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 45px;
    width: 150px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
}

.invisible {
    display: none;
}

.plug {

}

.meeting_href {
    text-decoration: none;
    color: teal;
    font-size: 18px;
    margin-left: 5px;
}

.modal {
    margin-top: 5px;
}

.loader {
    justify-content: center;
}

.entries_radio {
    display: flex;
    flex-direction: column;
    margin: 3px 0;
}

.query {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 10px;
    color: teal;
}

.query textarea {
    border: 2px solid teal;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: 200px;
    font-size: 16px;
    outline: none;
    color: teal;
}

.query div {
    gap: 5px;
    margin: 5px 0;
    align-items: center;
}

.query div input {
    margin-right: 5px;
}

.query button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 45px;
    width: 150px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
    margin: auto;
}

.href {
    color: teal;
}

.query_modal {
    width: 100%;
}

.entries .psycho_help_grey_button {
    border: 2px solid slategray;
    border-radius: 7px;
    height: 60px;
    width: 170px;
    padding: 2px 5px;
    color: slategray;
    font-size: 16px;
    cursor: pointer;
}

.feedback {
    color: teal;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    gap: 5px;
}

.feedback textarea {
    border: 2px solid teal;
    border-radius: 7px;
    height: 150px;
    padding: 5px;
    font-size: 16px;
}

.feedback button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 45px;
    width: 150px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
    margin: auto;
}

@media screen and (max-width: 500px) {
    .entries {
        flex-direction: column;
        align-items: center;
        margin: 0 5px;
    }

    .entries button {
        width: 100%;
    }

    .cons_info div {
        flex-direction: column;
        gap: 5px;
    }

    .cons_info div p {
        align-self: self-start;
    }

    .cons_info div h5 {
        width: 100%;
    }

    .meetings_buttons {
        justify-content: center;
    }

    .query {
        margin: 5px;
    }

    .query div {
        gap: 10px;
        margin: 10px 0 10px 0;
    }

    .query textarea {
        height: 350px;
    }

    .feedback {
        margin: 0 3px;
    }

    .feedback textarea {
        height: 200px;
    }
}